import $ from './app/jquery-shim.js';
import Utils from './app/utils.js';
import Accordion from './app/accordion.js';
import Anchor from './app/anchor.js';
import FixedHeader from './app/fixedheader.js';
import HeightLine from './app/heightline.js';
import ResponsiveTable from './app/responsive-table.js';
import Slidebar from './app/slidebar.js';
import Tab from './app/tab.js';
import CurrentNav from './app/current-nav.js';
import ScrollReveal from "scrollreveal";
import buildFormat from './app/format.js';
import OwlCarousel from 'owl.carousel';
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';

import fontAwesome from "font-awesome/scss/font-awesome.scss"
import OwlCss from "owl.carousel/dist/assets/owl.carousel.css"
import OwlThemeCss from "owl.carousel/dist/assets/owl.theme.default.css"
import DataPickerCss from "jquery-ui/themes/base/all.css"
datepickerFactory($);
datepickerJAFactory($);


class App {
  constructor() {
    this.Utils = new Utils();
    this.Accordion = new Accordion();
    this.Anchor = new Anchor();
    this.FixedHeader = new FixedHeader();
    this.HeightLine = new HeightLine();
    this.ResponsiveTable = new ResponsiveTable();
    this.Slidebar = new Slidebar();
    this.CurrentNav = new CurrentNav();
    this.Tab = new Tab();


    function slider() {

      $(".c-mainvisual-slider .owl-carousel").owlCarousel({
        items: 1,
        dots: true,
        loop: true,
        nav: false,
        autoplay: true,
        autoplaySpeed: 5000,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoWidth: false,
        autoHeight: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false
      });


      function setNavigationButtons(event) {
        $(".c-course-slider__thumbnail-item").removeClass("is-active");
        $(".c-course-slider__thumbnail-item:nth-child(" + (event.page.index + 1) + ")").addClass("is-active")

      }

      function initialize(event) {
        $(".c-course-slider__thumbnail-item:nth-child(1)").addClass("is-active");
      }


      var owl = $(".c-course-slider__slider").owlCarousel({
        items: 1,
        dots: true,
        loop: true,
        nav: true,
        navText: ["<img src='./assets/images/img-slide-arrow-left.png' alt=''>", "<img src='./assets/images/img-slide-arrow-right.png' alt=''>"],
        autoplay: true,
        autoplaySpeed: 5000,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoWidth: false,
        autoHeight: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        URLhashListener: true,
        onTranslate: setNavigationButtons,
        onInitialized: initialize,
      });

    }

    function scrollReveal() {

      // スクロールリーバル
      window.sr = new ScrollReveal({duration: 600, mobile: true});

      // scroll add class
      // https://github.com/scrollreveal/scrollreveal/issues/337#issuecomment-309657013
      sr.watch = function (target, onEnter, onExit) {
        onExit = onExit || function () {
        }
        if (typeof onEnter === 'function' && typeof onExit === 'function') {
          var noEffect = {
            delay: 0,
            distance: 0,
            duration: 0,
            scale: 1,
            opacity: null,
            rotate: {x: 0, y: 0, z: 0,},

            reset: true,
            beforeReset: onExit,
            beforeReveal: onEnter,
          }
          this.reveal(target, noEffect)
        } else {
          throw new Error('Watch received invalid arguments.')
        }
      };
      sr.watch('.js-reveal-blur',
        function onEnter(el) {
          el.classList.add('js-reveal-blur-active')
        },
        function onExit(el) {
          // el.classList.remove('js-reveal-blur-active')
        }
      )

      sr.reveal(".js-reveal-fade", {scale: 1, duration: 1200, distance: '0', delay: 300}, 100);
      sr.reveal(".js-reveal-fade-medium", {scale: 1, duration: 1200, distance: '0', delay: 500}, 100);
      sr.reveal(".js-reveal-fade-long", {scale: 1, duration: 600, distance: '0', delay: 1200}, 100);
      sr.reveal(".js-reveal-slidedown", {
        scale: 1,
        duration: 1200,
        distance: '20px',
        origin: 'top',
        delay: 300
      }, 100);
      sr.reveal(".js-reveal-slideup-short", {
        scale: 1,
        duration: 600,
        distance: '20px',
        origin: 'bottom',
        delay: 0
      }, 100);
      sr.reveal(".js-reveal-slideup-medium", {
        scale: 1,
        duration: 600,
        distance: '20px',
        origin: 'bottom',
        delay: 300
      }, 100);
      sr.reveal(".js-reveal-slideup-long", {
        scale: 1,
        duration: 600,
        distance: '20px',
        origin: 'bottom',
        delay: 600
      }, 100);
      sr.reveal(".js-reveal-slideleft", {
        scale: 1,
        duration: 600,
        distance: '20px',
        origin: 'right',
        delay: 600
      }, 100);

      sr.reveal(".js-reveal-slideright", {
        scale: 1,
        duration: 600,
        distance: '20px',
        origin: 'left',
        delay: 600
      }, 100);


    }

    function scrollAdaptive() {

      $(window).on('scroll', function (event) {
        // ヘッダーの高さを取得
        var header_height = $(".l-header").height() + 100;
        // elScrollableは別項「スクロール位置の取得」を参照
        var scroll_top = $(window).scrollTop();

        if (scroll_top >= header_height) {
          $("body").addClass("is-scrolling");
        } else {
          $("body").removeClass("is-scrolling");
        }
      });
    }

    $(function () {
      slider();
      scrollReveal();
      scrollAdaptive();

      $('.js-datepicker').datepicker({
        dateFormat: 'yy年mm月dd日(DD)',
        dayNames: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        minDate: new Date(),
        hideIfNoPrevNext: true,
      });
      $.datepicker.regional['ja'];


    });
  }
}

window.GApp = new App();
